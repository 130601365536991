import {WithId} from './find.utils';

export function trackById<T extends WithId>(_index: number, request: T) {
  return request.id;
}

export function generateTraceId(): string {
  return generateHexId(32);
}

export function generateTraceIdHeader(traceId: string): string {
  return `00-${traceId}-${generateHexId(16)}-00`;
}

function generateHexId(len: number) {
  const arr = new Uint8Array((len || 32) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0');
}
