/**
 * Msa Duty Service UI
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RecruitSchoolOptionDto } from './recruitSchoolOptionDto.model';


export interface DesiredRecruitSchoolDto { 
    /** @deprecated */
    isPZP?: boolean;
    recruitSchoolOptions: Array<RecruitSchoolOptionDto>;
    otState?: DesiredRecruitSchoolDtoOtStateEnum;
    isWZPSet?: boolean;
}
export enum DesiredRecruitSchoolDtoOtStateEnum {
    Past = 'PAST',
    Active = 'ACTIVE',
    Future = 'FUTURE'
};




