import {Directive, ElementRef, HostListener, inject, Renderer2} from '@angular/core';
import {environment} from 'src/environments/environment';
import {EasterEggManager} from './easter-egg.manager';

@Directive({selector: 'button', standalone: true})
export class FleeButtonDirective {
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  private easterEggManager = inject(EasterEggManager);

  @HostListener('mouseenter', ['$event']) onEnter() {
    if (environment.production) return;
    if (!this.easterEggManager.isFleeingButtonEnabled()) return;
    this.flee();
  }

  private flee() {
    if (environment.production) return;

    const button = this.elementRef.nativeElement;
    const buttonRect = button.getBoundingClientRect();
    const buttonWidth = 300;

    // Get the viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate new random position within the viewport
    let newTop = Math.random() * (viewportHeight - buttonRect.height);
    let newLeft = Math.random() * (viewportWidth - buttonWidth);

    // Ensure the button stays within the visible boundaries of the viewport
    newTop = Math.min(Math.max(newTop, 0), viewportHeight - buttonRect.height);
    newLeft = Math.min(Math.max(newLeft, 0), viewportWidth - buttonWidth);

    this.renderer.setStyle(button, 'position', 'fixed');
    this.renderer.setStyle(button, 'top', `${newTop}px`);
    this.renderer.setStyle(button, 'left', `${newLeft}px`);
    this.renderer.setStyle(button, 'width', `${buttonWidth}px`);
    this.renderer.setStyle(button, 'z-index', `999`);
  }
}
