import {DestroyRef, Injectable, computed, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {CookieService} from 'ngx-cookie-service';
import {EMPTY, Observable, catchError, map, take} from 'rxjs';

import {CookieConstants} from '../constants/cookie.const';

type UserDetails = {ahvNumber: string; name: string};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _userDetails = signal<UserDetails | undefined>(undefined);
  public userDetails = computed(() => this._userDetails());

  private hasPendingLogin = false;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private cookieService: CookieService,
    private destroyRef: DestroyRef
  ) {
    this.oidcSecurityService
      .isAuthenticated()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(auth => {
        if (auth) {
          this.authSuccessful();
        }
      });
  }

  public login$(params?: {referralRoute: string}): Observable<void> {
    if (this.hasPendingLogin) return EMPTY;
    this.hasPendingLogin = true;

    const referralUrl = params?.referralRoute ?? this.router.url;

    return this.oidcSecurityService.isAuthenticated().pipe(
      take(1),
      map(isAuthenticated => {
        if (!isAuthenticated) {
          // By design we don´t set the flag hasPendingLogins to false, since
          // the user leaves the page and the application reloads
          if (referralUrl) {
            sessionStorage.setItem(CookieConstants.REFERRAL_URL, referralUrl);
          }
          this.oidcSecurityService.authorize();
        } else {
          this.hasPendingLogin = false;
          // Route always to duties because referralUrl is never set
          this.router.navigate(['admin-query/duties']);
        }
      }),
      catchError(() => {
        this.hasPendingLogin = false;
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public login(params?: {referralRoute: string}): void {
    this.login$(params).subscribe();
  }

  // oidcSecurityService.checkAuth() is used to initialize the oidc library (start auth process). It should only be called once after returning from the login page (to avoid inconsistencies)
  public checkAuth(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(map(loginResponse => loginResponse.isAuthenticated));
  }

  //should be called after auth has been checked. (None of the oidcSecurityService observables seem to emit after successful authentication)
  public authSuccessful(): void {
    this.oidcSecurityService
      .getPayloadFromIdToken()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(payload => {
        if (payload) {
          this._userDetails.set({ahvNumber: payload.ahvNumber, name: payload.name});
        }
      });
  }

  public isAuthenticated(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$.pipe(map(({isAuthenticated}) => isAuthenticated));
  }

  public logout(): void {
    this.oidcSecurityService.logoff().subscribe(() => {
      this.cookieService.deleteAll();
      sessionStorage.clear();
      this.router.navigate(['']);
    });
  }
}
