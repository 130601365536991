<div
  class="tw-m-[-16px] !tw-min-w-[338px] d:!tw-min-w-[700px] snackbar-container tw-py-9 d:tw-py-12 tw-ps-9 tw-pe-15 tw-flex tw-justify-between tw-items-center {{
    data.type | lowercase
  }}"
>
  <div class="tw-flex tw-items-center tw-gap-9">
    @if (data) {
      <!-- div is needed to prevent icon being cut off -->
      <div>
        <mat-icon class="tw-text-white">{{ icon }}</mat-icon>
      </div>
    }

    <div class="snackbar-text tw-flex tw-flex-col tw-text-white">
      @for (key of data.key; track key; let i = $index) {
        <span class="msa-text">
          {{ key | translate: (data.interpolateParams && data.interpolateParams[i] ? data.interpolateParams[i] : {}) }}
        </span>
      }
    </div>
  </div>

  <button class="tw-flex tw-items-center" (click)="snackbarRef.dismiss()" data-cy="dismissSnackbar">
    <mat-icon class="tw-text-white">close_circle_outline</mat-icon>
  </button>
</div>
