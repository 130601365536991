/**
 * Msa Duty Service UI
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum TransportationType {
    PublicTransport = 'PUBLIC_TRANSPORT',
    PrivateVehicle = 'PRIVATE_VEHICLE',
    Combined = 'COMBINED'
}

