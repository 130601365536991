export enum MessageType {
  Success = 'SUCCESS',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR'
}

export const enum MessageTypeConst {
  Success,
  Info,
  Warning,
  Error
}

export enum MessageTypeIcon {
  SUCCESS = 'check_circle_outline',
  INFO = 'info_outline',
  WARNING = 'error_outline',
  ERROR = 'cancel'
}
