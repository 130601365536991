import {ChangeDetectorRef, inject, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {TranslationParams, TranslationString} from '@shared/shared-module/utils/translation.utils';

/**
 * This pipe wraps the ngx-translation pipe and ensures that
 * the input given is always a valid {@see TranslationString}.
 * Invalid strings given will lead to a compilation error
 */
@Pipe({
  name: 'safeTranslate',
  pure: false,
  standalone: true
})
export class SafeTranslatePipe implements PipeTransform {
  private translateService = inject(TranslateService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  private translatePipe = new TranslatePipe(this.translateService, this.changeDetectorRef);

  transform(value: TranslationString | undefined | null, ...args: TranslationParams): string {
    if (!value) return '';
    return this.translatePipe.transform(value, ...args);
  }
}
