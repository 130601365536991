/**
 * Is triggered on changing the language in the language dropdown.
 */
export class ChangeLanguage {
  static readonly type = '[app] Change language';

  constructor(public language: string) {}
}

/**
 * Is triggered on successfull authentication when user logs in.
 */
export class OnAuthenticated {
  static readonly type = '[app] on authenticated';
}
