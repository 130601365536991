import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, Optional} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {MessageType, MessageTypeIcon} from '../enums/messageType';

export interface SnackbarComponentData {
  type: MessageType;
  key: TranslationString[];
  interpolateParams?: {[key: string]: string}[];
}

@Component({
  selector: 'msa-snackbar',
  templateUrl: './msa-snackbar.component.html',
  styleUrl: './msa-snackbar.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, MatSnackBarModule, TranslateModule]
})
export class MsaSnackbarComponent {
  icon: string = '';

  constructor(
    @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarComponentData,
    public snackbarRef: MatSnackBarRef<MsaSnackbarComponent>
  ) {
    if (this.data) {
      this.icon = MessageTypeIcon[this.data.type];
    }
  }
}
