import {effect, inject, Injectable, signal} from '@angular/core';
import {environment} from 'src/environments/environment';
import {MessageType} from '../components/enums/messageType';
import {SnackbarService} from '../components/msa-snackbar/service/snackbar.service';
import {TranslationString} from '../utils/translation.utils';

@Injectable({providedIn: 'root'})
export class EasterEggManager {
  private snackbarService = inject(SnackbarService);

  private _isFleeingButtonEnabled = signal(false);

  // this imitates a ring buffer that holds 5 items max
  private fleeingButtonBuffer = signal<number[]>([]);

  constructor() {
    // triggers fleeing button, when the 5 clicks happened inside a 2s window
    effect(
      () => {
        if (environment.production) return;

        const clicks = this.fleeingButtonBuffer();
        if (clicks.length < 5) return;

        const firstClick = this.fleeingButtonBuffer()[0];
        const lastClick = this.fleeingButtonBuffer()[4];
        if (lastClick - firstClick > 2_000) return;

        this._isFleeingButtonEnabled.set(true);
        this.snackbarService.openSnackbar({
          text: 'Easter Egg: Fleeing button enabled! ;)' as TranslationString,
          type: MessageType.Info
        });
      },
      {allowSignalWrites: true}
    );
  }

  public toggleFleeingButton(): void {
    if (environment.production) return;

    const clicks = this.fleeingButtonBuffer();
    if (clicks.length >= 5) {
      clicks.shift();
    }
    this.fleeingButtonBuffer.set([...this.fleeingButtonBuffer(), Date.now()]);
  }

  public isFleeingButtonEnabled = this._isFleeingButtonEnabled.asReadonly();
}
