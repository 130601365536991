import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';

@Component({
  selector: 'msa-spinner',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div
    class="tw-h-full tw-w-full tw-animate-spin tw-items-center tw-rounded-full tw-border-[2px] tw-border-white tw-border-t-current tw-text-current"
  ></div>`
})
export class MsaSpinnerComponent {
  // fallback if no size is set, must be set on host
  @HostBinding('class') class = 'tw-text-navy tw-size-21 tw-inline-block';
}
