import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {isArray} from 'lodash';
import {MessageType} from '../../enums/messageType';
import {MsaSnackbarComponent, SnackbarComponentData} from '../msa-snackbar.component';

const DEFAULT_SNACKBAR_DURATION_MILLIS = 5000;

export type SnackbarOptions = {
  text: TranslationString | TranslationString[];
  interpolateParams?: {[key: string]: string}[];
  type: MessageType;
  durationMilliseconds?: number;
};

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private currentOpenSnackbar: MatSnackBarRef<MsaSnackbarComponent> | null = null;
  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone
  ) {}

  openSnackbar(options: SnackbarOptions) {
    const {text, type, interpolateParams} = options;
    const durationMilliseconds = options.durationMilliseconds ?? DEFAULT_SNACKBAR_DURATION_MILLIS;

    // running it in zone somehow avoids flickering the snackbar
    this.zone.run(() => {
      this.currentOpenSnackbar = this.snackBar.openFromComponent<MsaSnackbarComponent, SnackbarComponentData>(
        MsaSnackbarComponent,
        {
          data: {type, key: isArray(text) ? text : [text], interpolateParams},
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: durationMilliseconds
        }
      );
      setTimeout(() => this.dismissAll(), durationMilliseconds);
    });
  }

  hasOpenSnackbar(): boolean {
    return !!this.currentOpenSnackbar;
  }

  dismissAll(): void {
    if (this.currentOpenSnackbar) {
      this.currentOpenSnackbar.dismiss();
      this.currentOpenSnackbar = null;
    }
  }
}
