/**
 * Msa Duty Service UI
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SeasonDto {
    Summer = 'SUMMER',
    Winter = 'WINTER',
    Autumn = 'AUTUMN',
    Spring = 'SPRING'
}

