import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {FleeButtonDirective} from '@shared/shared-module/easter-egg/flee-button.directive';
import {MsaSpinnerComponent} from '../msa-spinner/msa-spinner.component';

export type MsaButtonColor = 'primary' | 'secondary';

@Component({
  selector: 'msa-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MsaSpinnerComponent, FleeButtonDirective],
  templateUrl: './msa-button.component.html'
})
export class MsaButtonComponent {
  public color = input<MsaButtonColor>('primary');
  public disabled = input<boolean>(false);
  public loading = input(false);
  widthClass = input<string>('tw-w-full');
}
