/**
 * Msa Duty Service UI
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum TrackingState {
    Army = 'BY_ARMY',
    University = 'BY_UNIVERSITY',
    ArmyCompleted = 'BY_ARMY_COMPLETED'
}

