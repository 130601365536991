/**
 * Msa Duty Service UI
 *
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum S3BucketTypeEnumDto {
    Pisa = 'PISA',
    Milo = 'MILO'
}

