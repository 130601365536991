@switch (color()) {
  @case ("primary") {
    <button
      class="{{
        widthClass()
      }} tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-12 tw-bg-navy tw-p-15 hover:enabled:tw-bg-navy-dark-1 active:enabled:tw-bg-navy-dark-2 active:enabled:tw-text-navy-light-3 disabled:tw-bg-navy-dark-4 disabled:tw-text-navy-light-1 d:tw-p-18"
      [disabled]="disabled() || loading()"
    >
      <ng-container *ngTemplateOutlet="buttonContent" />
    </button>
  }
  @case ("secondary") {
    <button
      class="{{
        widthClass()
      }} tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-12 tw-border-[1px] tw-border-white tw-p-15 hover:tw-border-black-light-8 hover:tw-text-black-light-8 active:tw-border-black-light-7 active:tw-text-black-light-7 disabled:tw-border-black-light-4 disabled:tw-text-black-light-4 d:tw-p-18"
      [disabled]="disabled() || loading()"
    >
      <ng-container *ngTemplateOutlet="buttonContent" />
    </button>
  }
}

<ng-template #buttonContent>
  <div class="msa-text-small tw-flex tw-flex-row tw-items-center tw-gap-12">
    @if (loading()) {
      <msa-spinner class="tw-h-50 tw-w-50" />
    }

    <ng-content />
  </div>
</ng-template>
